




















































import { Component, Prop, PropSync, Watch, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import CsvImportGuide from '@/components/CsvImportGuide.vue';
import FileUpload from '@/components/FileUpload.vue';
import EditableMemberTable from '@/views/AdminCreateGroup/components/EditableMemberTable.vue';
import GroupCsvImportInfo from '@/views/AdminCreateGroup/components/GroupCsvImportInfo.vue';
import { MemberObject } from '../../../utils/group.util';
import { ToastProgrammatic } from 'buefy';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { handleUserListUploading } from '@/utils/member-list-upload.util';
import { ValidateUserListPayload } from '../../../store/modules/admin/types/admin.types';
import { Action } from 'vuex-class';
import { cloneDeep } from 'lodash';
import { MyjbiGroupDetail } from '../../../jbi-shared/types/myjbi-group.types';
import { ValidateImportedMemberListPayload } from '@/store/modules/job-result-data/job-result-data.types';
import { isJobFailure } from '@/utils/bull-queue.util';

@Component({
  components: {
    ValidationProvider,
    CsvImportGuide,
    FileUpload,
    EditableMemberTable,
    GroupCsvImportInfo
  }
})
export default class MembersUploadSection extends Vue {
  @Prop(Function) downloadSampleXlsx!: () => void;
  @Prop(Function) downloadSampleCsv!: () => void;
  @Prop(Array) membersData!: MemberObject[];
  @PropSync('notify', Boolean) syncedNotify!: boolean;
  @Prop(Array) userAttributes!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @Prop() membersFile!: File;
  @PropSync('membersFile') syncedMembersFile!: File;
  @Prop(String) requiredCsvColumns!: string;
  @Prop(Object) validationErrors!: any;
  @Prop(Boolean) showMemberLabel!: boolean;
  @Prop(Boolean) shouldDownloadExistingMemberInCsv!: boolean;
  @Prop(String) csvFileName!: string;
  @Prop() allowedEmailDomains!: string[];

  public syncedMembersData: MemberObject[] = [];

  @Action('admin/uploadUserListFile')
  public uploadUserListFile!: (file: File) => Promise<any>;

  @Action('jobResultData/createImportedListValidationJob')
  public validateMemberList!: (
    payload: ValidateImportedMemberListPayload
  ) => Promise<any>;

  get errorNotification(): string {
    const erroredFieldCount = Object.entries(this.validationErrors)
      .filter(([key, arr]) => key.startsWith('obs_'))
      .map(([key, arr]) => (arr as string[]).length || 0)
      .flat()
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
    if (!erroredFieldCount) {
      return '';
    }
    return `${erroredFieldCount} missing field(s) detected.`;
  }

  get emailIndex() {
    return this.userAttributes
      ? this.userAttributes.findIndex(
          (attribute) =>
            attribute.groupUserAttribute.slug?.toLowerCase() === 'email'
        )
      : 0;
  }

  public updatePayloadOnPageLoad() {
    if (this.syncedMembersData) {
      this.syncedMembersData = this.syncedMembersData.map(
        (user: MemberObject) => {
          this.userAttributes?.map((attribute) => {
            const {
              slug,
              groupUserAttributeType
            } = attribute.groupUserAttribute;
            user[slug] = {
              value: user[slug] ? user[slug] : null,
              isRequired: attribute.required ? true : false,
              isValid: attribute.required && !user[slug] ? false : true,
              errorMessage:
                attribute.required && !user[slug]
                  ? 'This Field is Required'
                  : ''
            };
            if (groupUserAttributeType.type === 'date') {
              user[slug].value = user[slug]?.value
                ? new Date(user[slug].value)
                : null;
            }
          });
          return user;
        }
      );
    }
  }

  public updatePayloadOnAttributeChange() {
    if (this.syncedMembersData) {
      this.syncedMembersData = this.syncedMembersData.map(
        (user: MemberObject) => {
          this.userAttributes?.map((attribute) => {
            const {
              slug,
              groupUserAttributeType
            } = attribute.groupUserAttribute;
            user[slug] = {
              value: user[slug]?.value ? user[slug].value : null,
              isRequired: attribute.required,
              isValid: attribute.required && !user[slug]?.value ? false : true,
              errorMessage:
                attribute.required && !user[slug]
                  ? 'This Field is Required'
                  : ''
            };
            if (groupUserAttributeType.type === 'date') {
              user[slug].value = user[slug]?.value
                ? new Date(user[slug].value)
                : null;
            }
          });
          return user;
        }
      );
    }
  }

  @Watch('syncedMembersData', { deep: true })
  onSyncedMembersDataChange() {
    this.$emit('update:membersData', this.membersData);
  }

  @Watch('userAttributes')
  onAttributeChange() {
    this.updatePayloadOnAttributeChange();
  }

  @Watch('membersFile')
  @isTruthy
  public async onFilesAdded(file: File) {
    if (!this.membersFile) {
      return;
    }

    try {
      const gcsURL = await this.uploadUserListFile(file);

      const job = await this.validateMemberList({
        fileLink: gcsURL,
        emailIndex: this.emailIndex,
        userAttributesSpecs: this.userAttributes
          ? this.userAttributes.filter(
              (attribute) => attribute.groupUserAttribute.id
            )
          : [],
        emailDomains: this.allowedEmailDomains
      });

      const memberList = await handleUserListUploading.call(this, job);

      if (isJobFailure(memberList)) {
        this.$emit('clear');
      } else {
        this.syncedMembersData = memberList as MemberObject[];
      }

      this.$emit('uploadComplete', true);
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  public mounted() {
    this.syncedMembersData = cloneDeep(this.membersData);
    this.updatePayloadOnPageLoad();
  }
}
