/* tslint:disable */
import { NamedBullJobTypes } from '@/store/modules/job-result-data/job-result-data.enums';
import { jobResultDataState } from '@/store/modules/job-result-data/job-result-data.state';
import { GetJobResultDataPayload } from '@/store/modules/job-result-data/job-result-data.types';
import {
  LoadingProgrammatic as Loader,
  ToastProgrammatic as Toast
} from 'buefy';
import { BLoadingComponent } from 'buefy/types/components';
import { Job } from 'bull';
import Vue from 'vue';
import { WorkerJobStatus } from '../jbi-shared/types/cplus-worker.types';
import { EVENTS } from '../store/modules/websocket/websocket.state';
import { useAction } from './store.util';
import { useWebsocket } from './websocket.util';

const displayToastError = () => {
  Toast.open({
    message: `Error processing. Please try again later.`,
    duration: 3000,
    type: 'is-danger'
  });
};

/* tslint:disable */
export const handleUserListUploading = async function (
  this: Vue,
  job: Job,
  element?: HTMLDivElement
) {
  if (!job) {
    displayToastError();
  }

  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo
  } = useWebsocket.call(this);

  const { id: jobId } = job;

  const markWorkerJobAsCompleted: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsCompleted'
  );
  const markWorkerJobAsErrored: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsErrored'
  );
  const getJobResultData: (
    payload: GetJobResultDataPayload
  ) => Promise<any> = useAction.call(this, 'jobResultData/getJobResultData');

  const loaderComponent: BLoadingComponent = Loader.open({
    container: element
  });

  await connectToWs();

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(
      EVENTS.VALIDATE_IMPORTED_MEMBER_LIST_EVENT
    )) as Job;
  } catch (error) {
    loaderComponent.close();
    markWorkerJobAsErrored(job);
    displayToastError();
    await disconnectWs();
    return;
  }

  const { status, message } = job.returnvalue;
  switch (status) {
    case WorkerJobStatus.PROCESSED:
      // Construct payload to retrieve job result data
      const payload: GetJobResultDataPayload = {
        bullJobId: +job.id,
        fromNamedJob: NamedBullJobTypes.VALIDATE_MEMBER
      };

      try {
        await getJobResultData(payload);
        if (jobResultDataState.jobResultData) {
          loaderComponent.close();
          markWorkerJobAsCompleted(job);
          Toast.open({
            queue: true,
            position: 'is-top',
            message
          });

          return jobResultDataState.jobResultData;
        }
      } catch (error) {
        loaderComponent.close();
        markWorkerJobAsErrored(job);
        displayToastError();
      }
    case WorkerJobStatus.FAILED:
      loaderComponent.close();
      markWorkerJobAsErrored(job);
      Toast.open({
        message,
        duration: 3000,
        type: 'is-danger'
      });

      return job.returnvalue;
    default:
      loaderComponent.close();
      markWorkerJobAsErrored(job);
      displayToastError();
  }

  await disconnectWs();

  return;
};
