








































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { generateGroupCreationMixin } from '@/views/AdminCreateGroup/mixins/group-creation.mixin.ts';
import GroupAttributeForm from '@/views/AdminCreateGroup/components/GroupAttributeForm.vue';
import GroupNameAndTypeForm from '../AdminCreateGroup/components/GroupNameAndTypeForm.vue';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';
import { MyjbiGroupDetail } from '@/jbi-shared/types/myjbi-group.types';
import EmailDomainForm from '@/views/AdminCreateGroup/components/EmailDomainForm.vue';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import ProtectedGroupSection from '@/views/AdminCreateGroup/components/ProtectedGroupSection.vue';
import { ProtectedGroupTypes } from '@/store/modules/admin/types/group.types';
import { GetProtectedGroupTypesResponsePayload } from '@/store/modules/admin/types/admin.types';
import GroupLevelAttribute from '../AdminGroupSettings/components/GroupLevelAttribute.vue';
import UserAttributeTab from '../AdminCreateGroup/components/UserAttributeTab/UserAttributeTab.vue';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { isUserAllowed } from '@/utils/rbac.util';

interface MemberObject {
  email?: string;
  [key: string]: any;
}

@Component({
  components: {
    GroupAttributeForm,
    EmailDomainForm,
    GroupNameAndTypeForm,
    ProtectedGroupSection,
    GroupLevelAttribute,
    UserAttributeTab
  }
})
export default class AdminCreateSubGroup extends mixins(
  generateGroupCreationMixin()
) {
  public allowedEmailDomains: string[] = [];
  public selectedProtectedGroupData: ProtectedGroupTypes | null = null;
  public protectGroup: boolean = false;

  @Action('admin/getMasterGroupEmailDomains')
  getAncestorFullGroupDetail!: (id: number) => Promise<string[]>;

  @Action('admin/getParentGroupDetailByParentGroupId')
  getParentGroupDetailByParentGroupId!: (
    id: number
  ) => Promise<MyjbiGroupDetail>;

  @Action('admin/getProtectedGroupTypes')
  getProtectedGroupTypes!: () => void;

  @State((state: RootState) => state.admin.protectedGroupTypes)
  public protectedGroupTypes!: GetProtectedGroupTypesResponsePayload;

  get getParentGroupDetailByParentGroupIdSuccess() {
    return (this.$store.state as RootState).admin.apiState
      .getParentGroupDetailByParentGroupId.success;
  }

  get masterGroupEmailDomains() {
    return (this.$store.state as RootState).admin.masterGroupEmailDomains;
  }

  get parentGroupId(): number {
    return +this.$route.params.parentGroupId;
  }

  get parentGroupIsProtected(): boolean {
    return Boolean(this.$route.params.isProtected);
  }

  get parentGroupDetailByParentGroupId() {
    return (this.$store.state as RootState).admin
      .parentGroupDetailByParentGroupId;
  }

  get parentProtectedTypes() {
    if (this.selectedProtectedGroupData) {
      this.selectedProtectedGroupData.group_id = this.parentGroupId;
      this.selectedProtectedGroupData.protected_group_type_id = this.selectedProtectedGroupData.id;
    }
    return {
      isProtected: this.selectedProtectedGroupData ? true : false,
      protectedType: this.selectedProtectedGroupData
    };
  }

  get parentType(): string | undefined {
    return (this.$store.state as RootState).admin
      .parentGroupDetailByParentGroupId?.types[0];
  }

  public getEmailDomains() {
    // get email domains from current group details
    const emailDomainFromParent = this.parentGroupDetailByParentGroupId
      ?.emailDomains;

    // get email domains from root ancestor
    const emailDomainFromAncestor = this.masterGroupEmailDomains;

    // if this group detail has email domains, use that
    if (emailDomainFromParent && emailDomainFromParent.length > 0) {
      return emailDomainFromParent;
      // if not, use the one you get from root ancestor (master group)
    } else if (emailDomainFromAncestor && emailDomainFromAncestor.length > 0) {
      return emailDomainFromAncestor;
    } else {
      return [];
    }
  }

  public subGroupCreationFormHasError(invalid: boolean): boolean {
    return (
      invalid ||
      !this.$data.isValidGroup ||
      this.$data.groupLevelAttributeFormHasError
    );
  }

  @Watch('parentGroupDetailByParentGroupId')
  @isTruthy
  onParentGroupDetailLoaded() {
    this.allowedEmailDomains = this.getEmailDomains();
  }

  @Watch('membersData', { immediate: true, deep: true })
  onMembersDataChanged() {
    this.validateGroup();
  }

  @Watch('groupLevelAttributeFormHasError')
  onGroupLevelAttributeFormHasErrorChanged() {
    this.validateGroup();
  }

  public validateGroup() {
    this.isValidGroupMember(this.membersData);
  }

  public isValidGroupMember(members: MemberObject[] | null): true | undefined {
    if (!members) {
      this.$data.isValidGroup = true;
      return true;
    }

    if (members.length > 0 && typeof members[0].email === 'string') {
      this.$data.isValidGroup = true;
      return true;
    }

    const checkMemberListIsValid = members.filter((user: MemberObject) => {
      const invalidAttribute = Object.entries(user)
        .map(([, value]) => (value as { isValid: boolean }).isValid)
        .filter((attribute) => attribute === false);

      return invalidAttribute.length > 0;
    });

    this.$data.isValidGroup = checkMemberListIsValid.length === 0;
    return this.$data.isValidGroup ? true : undefined;
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      this.parentType,
      this.groupId,
      undefined,
      skipImplicitCheck
    );
  }

  public mounted() {
    this.getParentGroupDetailByParentGroupId(+this.$route.params.parentGroupId);
    if (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups-read_email_domain',
        true
      )
    ) {
      this.getAncestorFullGroupDetail(+this.$route.params.parentGroupId);
    }
    this.getProtectedGroupTypes();
  }
}
